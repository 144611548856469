import act from '../main/action-const'

const INITIAL_STATE = {
    line: []
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case act.JORNADA_LISTADA:
            return {
                ...state, line: action.payload,
            }

        case act.JORNADA_ADD_QUEST:
            const line = [...state.line]
            const id_inscricao = action.payload.id_inscricao ?? 0
            const i = line.findIndex(it => it?.id_inscricao === id_inscricao)
            if (i > -1)
                line[i]['line'] = action.payload?.data
            return {
                ...state, line: [...line],
            }


        case act.JORNADA_CLEAR:
            return {
                INITIAL_STATE,
            }

        default:
            return state
    }
}
