import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContentSection, Row } from '../common/layout'
import PageSetup from '../main/page-setup'
import CardImageTop from '../common/widget/card-image-top'
import { BadgeRed, BadgeYellow } from '../common/ui/badge'
import { date_loc } from '../sys/normalize-location'
import { meuCursoSituacao } from './meu-curso-situacao'

import config from '../main/config'
import { listar } from './meus-cursos-action'
import { CalloutInfo } from '../common/msg/callout'


class MeusCursos extends Component {

    componentDidMount() {
        this.props.listar([])
    }

    render_prazo(dados) {
        if (dados?.expirado === '1')
            return (
                <>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>
                    <BadgeRed>Curso Expirado</BadgeRed>
                </>
            )

        if (dados?.dt_prazo && (parseInt(dados?.faltam_dias) <= 7))
            return (
                <>
                    <BadgeRed>{dados?.faltam_dias} dias</BadgeRed>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>

                </>
            )

        if (dados?.dt_prazo)
            return (
                <>
                    <BadgeYellow>{dados?.faltam_dias} dias</BadgeYellow>
                    <BadgeYellow>Prazo: {date_loc(dados?.dt_prazo)}</BadgeYellow>
                </>
            )
    }
    renderCursos(dados) {
        if (dados.length === 0) {
            return (
                <CalloutInfo title="Nada por aqui. Faça inscrição em um curso e continue aprendendo!">
                </CalloutInfo>
            )
        }

        const cfg = config()

        return dados.map(vo => {
            return (
                <CardImageTop
                    key={'curso' + vo['id_inscricao']}
                    col_class="col-md-3"
                    minHeightContent={'150px'}
                    route={`/meu-curso-info/${vo['id_inscricao']}`}
                    image={cfg.CDN_PATH + 'curso.png'}
                    color_class={'bg-aqua-active'}
                    title={vo['titulo']}
                    subtitle={vo['subtitulo']}
                >
                    {this.render_prazo(vo)}
                    {meuCursoSituacao(vo['sit_inscricao'])}
                    {vo['media'] && (<><br /><h4><b>Nota: {vo['media']}</b></h4></>)}
                </CardImageTop>
            )
        })
    }

    render_view() {
        return (
            <PageSetup title='Meus Cursos' subtitle='Lista'>
                <ContentSection>
                    <Row>
                        {this.renderCursos(this.props.meus_cursos.lista)}
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

    render() {
        return this.render_view()
    }

}

const mapStateToProps = state => ({ meus_cursos: state.meusCursos })
const mapDispatchToProps = dispatch => bindActionCreators({ listar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MeusCursos)

