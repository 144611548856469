import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '../common/helper'

import { date_time_loc } from '../sys/normalize-location'

const go_link = (text, linkto) => {
    return (
        <Link to={linkto}>
            {text}
        </Link>
    )
}

const badge = (badge, pkey) => {
    if (!Array.isArray(badge))
        return (<></>)
    return (<span>{(badge).map(b => {
        const bs = b.split('|')
        return (<span key={pkey + '-' + (Math.random())} className={`pull-right margin-r-5 badge bg-${bs[1]}`}>
            {bs[0] ?? 'indefinido'}
        </span>)
    })}
        <div className="clearfix"></div>
    </span>)
}


export default function Jornada({ line, obter_quest }) {

    function add_quest(vo) {
        if (vo?.id_inscricao)
            return (
                <Anchor className="pull-right" onClick={() => obter_quest(vo.id_inscricao)
                }>&nbsp;<i className="fa fa-question-circle fa-2x" ></i></Anchor>
            )
    }

    function show_line(vo, style) {
        return (
            <h3 className="timeline-header" style={style}>
                {start_icon(vo)}
                <span className="margin-r-5">{vo['route'] ? go_link(vo['text'], vo['route']) : vo['text']}</span>
                {add_quest(vo)}
                {badge(vo['badge'], vo['id'])}
            </h3>
        )

    }
    function show_time(vo) {
        if (vo['time'])
            return (
                <span className="time"><i className="fa fa-clock-o"></i>&nbsp;
                    {date_time_loc(vo['time'][0])}  {vo['time'][1] ? ' | ' + date_time_loc(vo['time'][1]) : ''}
                </span>
            )
    }

    function show_icon(vo) {
        if (vo['icon'])
            return (
                <i className={`fa fa-${vo['icon'] ?? 'clock-o'} bg-${(vo['icon-color'] ?? vo['color'])}`}></i>
            )
    }

    function start_icon(vo) {
        if (vo['start-icon'])
            return (
                <i className={`fa fa-${vo['start-icon'] ?? 'clock-o'} bg-${(vo['icon-color'] ?? vo['color']) ?? 'blue'} margin-r-5`}
                    style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        textAlign: "center",
                        fontSize: "15",
                        lineHeight: "30px"
                    }}></i>

            )
    }

    function get_style(vo) {
        const style = {}
        if (vo['size'])
            style['fontSize'] = vo['size'];

        if (vo['weight'])
            style['fontWeight'] = vo['weight'];
        return style
    }

    function get_style_line(vo) {
        const style_line = {}
        if (vo['margin'])
            style_line['marginLeft'] = vo['margin'] + 'px';
        return style_line
    }

    function render_label(vo, style) {
        return (
            <li key={vo['id']} className="time-label">
                <span className={`bg-${vo['color'] ?? 'blue'}`} style={style}>
                    {vo['text']}
                </span>
            </li>
        )
    }

    function render_sub_line(line) {
        if (!Array.isArray(line))
            return (<></>)

        return (line).map(vo => {
            const style = get_style(vo)
            const style_line = get_style_line(vo)

            style['borderLeft'] = `5px solid ${vo['color'] ?? 'blue'}`;
            style['marginTop'] = "5px";
            return (
                <div key={vo['id']} className="timeline-item" style={style_line} >
                    {show_time(vo)}
                    {show_line(vo, style)}
                </div >
            )

        })
    }

    function render_line(line) {
        if (!Array.isArray(line)) {
            return (
                <li className="time-label">
                    <span className="bg-blue">
                        Jornada não iniciada!
                    </span>
                </li>
            )
        }

        return (line).map(vo => {
            const style = get_style(vo)
            const style_line = get_style_line(vo)

            if (vo['type'] === 'label')
                return render_label(vo, style)

            style['borderLeft'] = `5px solid ${vo['color'] ?? 'blue'}`;

            if (vo['type'] === 'line')
                return (
                    <li key={vo['id']}>
                        {show_icon(vo)}
                        <div className="timeline-item" style={style_line} >
                            {show_time(vo)}
                            {show_line(vo, style)}
                        </div >
                        {vo['line'] && render_sub_line(vo['line'])}
                    </li>
                )

            return (<></>)
        })
    }

    return (
        <ul className="timeline">
            {render_line(line)}
            <li>
                <i className="fa fa-clock-o bg-gray"></i>
            </li>
        </ul>
    )
}