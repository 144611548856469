import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection } from '../common/layout'
import { Row, Grid } from '../common/layout'

import { obter_jornada, obter_quest } from './jornada-action'


import Jornada from './jornada'

class MinhaJornada extends Component {


    componentDidMount() {
        //this.props.obter()
        this.props.obter_jornada()
    }


    render() {
        return (
            <PageSetup title='Minha Jornada'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-9">
                            <Jornada
                                line={this.props.jornada?.line ?? []}
                                obter_quest={(id_inscricao) => this.props.obter_quest(id_inscricao)}
                            />
                        </Grid>
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }
}


const mapStateToProps = state => ({
    jornada: state.jornada,
    login: state.login,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    obter_jornada, obter_quest
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MinhaJornada)

