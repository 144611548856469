import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { salvar_pergunta } from './minhas-aulas-action'

import Modal from '../common/ui/modal'


class MinhaAulaPerguntaAdd extends Component {

    constructor(props) {
        super(props);
        this.state = { texto: '', peso: '0', tamanho: 0 }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangePeso = this.handleChangePeso.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    tem_acesso(acesso) {
        let classe = (this.props.login.usuario['classe'] || '').split(',')
        for (let i in classe) {
            if (acesso.includes(classe[i])) {
                return true
            }
        }
        return false
    }


    handleChange(event) {
        this.setState({ texto: event.target.value, tamanho:  event.target.value.length});
    }

    handleChangePeso(event) {
        this.setState({ ...this.state,  peso: event.target.value});
    }


    handleSubmit(event) {
        this.props.salvar_pergunta(this.props.minhas_aulas['dados']['id_aula'], this.state['texto'], this.state['peso'])

        this.setState({ texto: '', peso: '0', tamanho: 0 });
        event.preventDefault();
    }

    renderPeso() {
        return (
            <>
                <hr className="clearfix"></hr>
                <p>Se a pergunta vale nota informe o peso!</p>
                <div className="form-group">
                    <select className="form-control" 
                        onChange={this.handleChangePeso}
                        value={this.state.peso}>
                        <option value="0">Sem peso</option>
                        <option value="0.10">Peso 0,10</option>
                        <option value="0.25">Peso 0,25</option>
                        <option value="0.5">Peso 0,5</option>
                        <option value="1">Peso 1</option>
                        <option value="1.5">Peso 1.5</option>
                        <option value="2">Peso 2</option>
                    </select>
                </div>
            </>
        )
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Modal
                    id='minha-aula-pergunta-add'
                    title='Pergunta'
                    confirmLabel='Confirmar'
                    confirmButtonType='submit'
                    onConfirm={() => { return true }}
                    onClose={() => { return true }}
                >
                    <div className="form-group">
                        <textarea className="form-control" 
                            value={this.state.texto} 
                            onChange={this.handleChange} 
                            rows={4} 
                            maxLength={300}
                        />
                    </div>
                    <div className="clearfix"></div>
                    Tamanho: {this.state['tamanho']}/300
                    {this.tem_acesso('professor,tutor') && 
                     this.props.minhas_aulas['dados'] &&
                     this.props.minhas_aulas['dados']['id_grade_item'] &&
                     this.renderPeso()
                    }
                </Modal>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    minhas_aulas: state.minhasAulas,
    login: state.login
})
const mapDispatchToProps = dispatch => bindActionCreators({ salvar_pergunta }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MinhaAulaPerguntaAdd)

