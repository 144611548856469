import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from '../home/home'
import PerfilShow from '../perfil/perfil-show'
import MeusCursos from '../meus-cursos/meus-cursos'
import MeuCursoInfo from '../meus-cursos/meu-curso-info'
import MinhaAulaShow from '../minhas-aulas/minha-aula-show'
import Resp from '../quest-resp/resp'
import RespLista from '../quest-resp/resp-lista'
import ProximoCurso from '../proximo-curso/proximo-curso'
import ProximoCursoInfo from '../proximo-curso/proximo-curso-info'
import Carrinho from '../inscricao/carrinho'
import MinhaJornada from '../jornada/minha-jornada'
import Extrato from '../extrato/extrato'

export default props => (

    <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/home' component={Home} />
        <Route exact path='/perfil-show' component={PerfilShow} />
        <Route exact path='/meus-cursos' component={MeusCursos} />
        <Route exact path='/meu-curso-info/:id_inscricao' component={MeuCursoInfo} />
        <Route exact path='/minha-aula-show/:id_inscricao/:id_aula' component={MinhaAulaShow} />
        <Route exact path='/quest-resp/:id_resp' component={Resp} />
        <Route exact path='/resp-list' component={RespLista} />
        <Route exact path='/proximo-curso' component={ProximoCurso} />
        <Route exact path='/proximo-curso-info/:id_curso/:id_grade_item' component={ProximoCursoInfo} />
        <Route exact path='/pedidos' component={Carrinho} />
        <Route exact path='/minha-jornada' component={MinhaJornada} />
        <Route exact path='/extrato' component={Extrato} />
        <Redirect from='*' to="/" />
    </Switch>

)

