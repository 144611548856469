import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Row } from '../common/layout'
import CardImageTop from '../common/widget/card-image-top'

import config from '../main/config'
import { listar } from './meus-cursos-action'


class MeusCursosLista extends Component {
   
    componentDidMount() {
        this.props.listar([])
    }


    renderCursos(dados) {
        const cfg = config()

        return dados.map(vo => {
            return(
                <CardImageTop
                    key={'inscr'+vo['id_inscricao']} 
                    col_class="col-md-3"
                    minHeightContent={'70px'}
                    route={`/meu-curso-info/${vo['id_inscricao']}`}
                    image={cfg.CDN_PATH +'/curso.png'}
                    color_class={ 'bg-aqua-active' }
                    title={vo['titulo']}
                />
            )
        })
    }

    render_view() {
        return(
            <Row>
                {this.renderCursos(this.props.meus_cursos.lista)}
            </Row>
        )
    }

    render() {
        return this.render_view()
    }

}

const mapStateToProps = state => ({meus_cursos: state.meusCursos})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MeusCursosLista)

