import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { obterContadores } from './dashboard-action'

import { Row } from '../common/layout'
import Smallbox from '../common/widget/small-box'


import curso_ou_disciplina from '../main/curso-ou-disciplina'

class Contadores extends Component {
  
    componentDidMount() {
        this.props.obterContadores()
    }


    render() {
        return(
            <Row>
                <Smallbox 
                    icon="glyphicon glyphicon-book"
                    value={this.props.dash['total_cursos'] || 0}
                    sup=""
                    title={curso_ou_disciplina("Cursos", "Disciplinas")}
                    add_class="bg-green"
                    cols="md-3"
                    route="/meus-cursos"
                    linkLabel="Ver todos os cursos"
                />

                <Smallbox 
                    icon="fa fa-question-circle"
                    value={this.props.dash['total_resp'] || 0}
                    sup=""
                    title="Meus Questionários"
                    add_class="bg-yellow"
                    cols="md-3"
                    route="/resp-list"
                    linkLabel="Ver todos os questionários"
                />
            </Row>
        )
    }

}

const mapStateToProps = state => ({dash: state.dash})
const mapDispatchToProps = dispatch => bindActionCreators({ obterContadores }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Contadores)

