import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {date_time_loc} from '../sys/normalize-location'

import { BoxGroup, BoxWidget, BoxBody,BoxFooterComments, BoxComments, BoxFooter } from '../common/widget/box'
import { Icon } from '../common/ui'
import { LabelInfo } from '../common/ui/labels' 

import { modal_open } from '../common/ui/modal'
import { set_pergunta, like, atribuir_pontos } from './minhas-aulas-action'
import config from '../main/config'


class MinhaAulaPergunta extends Component {

    tem_acesso(acesso) {
        let classe = (this.props.login.usuario['classe'] || '').split(',')
        for (let i in classe) {
            if (acesso.includes(classe[i])) {
                return true
            }
        }
        return false
    }



    render_uma_pergunta(pergunta) {
        const img_perfil_defa = config().IMAGE_PATH + 'profile.jpg'
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_perfil = (pergunta['foto_file']) ? (cdn_endpoint + pergunta['foto_file']) : (img_perfil_defa)

        return (
        <React.Fragment key={`per-fra-${pergunta['id_pergunta']}`}>
            <BoxFooter />
            <BoxGroup key={`per-bg-${pergunta['id_pergunta']}`}
                image={img_perfil}
                title={(pergunta['professor'] && pergunta['professor'] === '1' ?'Prof. ': ' ') + pergunta['nome']}
                description={date_time_loc(pergunta['dh_inc'])}
            />
            <BoxBody key={`per-bb-${pergunta['id_pergunta']}`}>
                { (parseFloat(pergunta['peso']) > 0) &&
                    <h5><Icon icon="fa fa-diamond text-aqua"/><LabelInfo title={`peso ${pergunta['peso']}`}/></h5>
                }
                <p>{pergunta['texto']}</p>

                <button type="button" className="btn btn-default btn-xs"
                    onClick={()=>{this.props.set_pergunta(pergunta['id_pergunta']); modal_open('minha-aula-resposta-add')}} 
                ><i className="fa fa-commenting-o"></i> Comentar</button>
                {(pergunta['eu_mesmo'] !== '1') &&
                    <button type="button" className="btn btn-default btn-xs" 
                        onClick={()=>{this.props.like(this.props.minhas_aulas.dados['id_aula'], pergunta['id_pergunta'])}}
                    ><i className={`fa fa-thumbs-${pergunta['meu_like'] === '1'?'up text-aqua':'o-up'}`}></i> Like</button>
                }
                <span className="pull-right text-muted">{pergunta['qt_like']} likes - {pergunta['qt_comentarios']} comentários</span>

            </BoxBody>
        </React.Fragment>
        )
    }

    render_estrelas(pontos) {
        if (pontos === 2) return (<i className='fa fa-star-o'/>)
        if (pontos === 4) return (<><i className='fa fa-star-o'/><i className='fa fa-star-o'/></>)
        if (pontos === 6) return (<><i className='fa fa-star'></i><i className='fa fa-star'/><i className='fa fa-star-half-empty'/></>)
        if (pontos === 8) return (<><i className='fa fa-star'></i><i className='fa  fa-star'/><i className='fa  fa-star'/><i className='fa  fa-star'/></>)
        if (pontos === 10) return (<><i className='fa fa-star'></i><i className='fa fa-star'/><i className='fa fa-star'/><i className='fa  fa-star'/><i className='fa fa-star'/></>)
    }

    render_botoes_pontos(resposta) {
        return (
            <>
               <button type="button" className="btn btn-info btn-xs"  title="Insuficiente"
                    onClick={()=>{this.props.atribuir_pontos(this.props.minhas_aulas.dados['id_aula'], resposta['id_pergunta'], 2)}}
                >{this.render_estrelas(2)}</button>

                <button type="button" className="btn btn-info btn-xs" title="Fraco"
                    onClick={()=>{this.props.atribuir_pontos(this.props.minhas_aulas.dados['id_aula'], resposta['id_pergunta'], 4)}}
                >{this.render_estrelas(4)}</button>

                <button type="button" className="btn btn-info btn-xs"  title="Regular"
                    onClick={()=>{this.props.atribuir_pontos(this.props.minhas_aulas.dados['id_aula'], resposta['id_pergunta'], 6)}}
                >{this.render_estrelas(6)}</button>

                <button type="button" className="btn btn-info btn-xs"  title="Bom"
                    onClick={()=>{this.props.atribuir_pontos(this.props.minhas_aulas.dados['id_aula'], resposta['id_pergunta'], 8)}}
                >{this.render_estrelas(8)}</button>

                <button type="button" className="btn btn-info btn-xs"  title="Excelente"
                    onClick={()=>{this.props.atribuir_pontos(this.props.minhas_aulas.dados['id_aula'], resposta['id_pergunta'], 10)}}
                >{this.render_estrelas(10)}</button>


            </>
        )
    }

    render_uma_resposta(resposta) {
        const img_perfil_defa = config().IMAGE_PATH + 'profile.jpg'
        const cdn_endpoint = this.props.login.usuario['cdn_endpoint']
        const img_perfil = (resposta['foto_file']) ? (cdn_endpoint + resposta['foto_file']) : (img_perfil_defa)

        return (
            <BoxFooterComments key={`res-co-${resposta['id_pergunta']}`}>
                <BoxComments
                    title={(resposta['professor'] && resposta['professor'] === '1' ?'Prof. ': ' ') + resposta['nome']}
                    image={img_perfil}
                    right={date_time_loc(resposta['dh_inc'])}
                >
                    { (parseInt(resposta['pontos']) > 0) &&
                        <h5><LabelInfo>{this.render_estrelas(parseInt(resposta['pontos']))}</LabelInfo></h5>
                    }

                    <p>{resposta['texto']}</p>

                    {(resposta['eu_mesmo'] !== '1') &&
                        <button type="button" className="btn btn-default btn-xs" style={{marginRight:15}}
                            onClick={()=>{this.props.like(this.props.minhas_aulas.dados['id_aula'], resposta['id_pergunta'])}}
                        ><i className={`fa fa-thumbs-${resposta['meu_like'] === '1'?'up text-aqua':'o-up'}`}></i> Like</button>
                    }

                    {(parseFloat(resposta['peso']) > 0 ) && 
                    this.tem_acesso('professor,tutor') &&
                    this.render_botoes_pontos(resposta)  }


                    <span className="pull-right text-muted">{resposta['qt_like']} likes</span>
                
                </BoxComments>
            </BoxFooterComments>
        )
    }


    render_perguntas() {
        const perguntas = this.props.minhas_aulas['perguntas'] || []

        if (perguntas.length < 1) {
            return (
                <BoxBody>
                    <p>Esta aula não tem perguntas.</p>
                </BoxBody>
            )
        }

        return perguntas.map((pergunta) => {
            if (pergunta['tipo'] === '1') {
                return this.render_uma_pergunta(pergunta)
            } else {
                return this.render_uma_resposta(pergunta)
            }
        })
    }

    render() {
        return (
            <BoxWidget title="Perguntas">
                <BoxBody>
                    <button type="button" className="btn pull-right" onClick={()=>{modal_open('minha-aula-pergunta-add')}} >Faça uma Pergunta <i className="fa fa-question-circle"></i></button>
                </BoxBody>
{/*
                <BoxBody>
                    <button type="button" className="btn btn-block btn-sm"><i className="fa fa-chevron-up"></i></button>
                </BoxBody>
*/}
                {this.render_perguntas()}

            </BoxWidget>
        )
    }

}

const mapStateToProps = state => ({
    minhas_aulas: state.minhasAulas,
    login: state.login
})
const mapDispatchToProps = dispatch => bindActionCreators({ set_pergunta, like, atribuir_pontos }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MinhaAulaPergunta)

