import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { decodeBase64 } from '../sys/base64'
import config from '../main/config'
import { date_loc } from '../sys/normalize-location'

import { BoxWidget, BoxBody } from '../common/widget/box'
//import { ProgressBarAqua } from '../common/ui/progress-bar'
import { BadgeRed, BadgeYellow } from '../common/ui/badge'
import { meuCursoSituacao } from './meu-curso-situacao'


class CursoShow extends Component {

    render_prazo(dados) {
        if (dados?.expirado === '1')
            return (
                <>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>
                    <BadgeRed>Curso Expirado</BadgeRed>
                </>
            )

        if (dados?.dt_prazo && (parseInt(dados?.faltam_dias) <= 7))
            return (
                <>
                    <BadgeRed>{dados?.faltam_dias} dias</BadgeRed>
                    <BadgeRed>Prazo: {date_loc(dados?.dt_prazo)}</BadgeRed>

                </>
            )

        if (dados?.dt_prazo)
            return (
                <>
                    <BadgeYellow>{dados?.faltam_dias} dias</BadgeYellow>
                    <BadgeYellow>Prazo: {date_loc(dados?.dt_prazo)}</BadgeYellow>
                </>
            )
    }

    render() {
        const cfg = config()
        const dados = this.props.meus_cursos.dados
        const sit_curso = meuCursoSituacao(this.props.meus_cursos.dados['sit_inscricao'])

        return (
            <BoxWidget
                title={this.props.meus_cursos.dados['titulo']}
                subtitle={this.props.meus_cursos.dados['subtitulo']}>
                <BoxBody>
                    {sit_curso}
                    {this.render_prazo(dados)}

                    {/* <BadgeAqua title={this.props.meus_cursos.dados['sit_inscricao_label']} /> */}
                    <img className="img-responsive pad" src={cfg.CDN_PATH + 'curso.png'} alt="Curso" />
                    {/* <ProgressBarAqua value={this.props.meus_cursos.dados['per_aulas']} label='Andamento' /> */}
                    {/* <ProgressBarAqua value={this.props.meus_cursos.dados['aproveitamento']} label='Aproveitamento' /> */}

                    <h4><b>Visão Geral</b></h4>
                    <div dangerouslySetInnerHTML={{ __html: decodeBase64(this.props.meus_cursos.dados['visao_geral']) }}></div>
                    {/*
                    <br/><h4><b>Descrição</b></h4>
                    <div dangerouslySetInnerHTML={{__html: decodeBase64(this.props.meus_cursos.dados['descricao']) }}></div>
                     
                    */}
                    <br /><h4><b>Pré-Requistos</b></h4>
                    <div dangerouslySetInnerHTML={{ __html: decodeBase64(this.props.meus_cursos.dados['pre_requisitos']) }}></div>
                    {this.props.meus_cursos.dados['media'] && (<><br /><h4><b>Nota: {this.props.meus_cursos.dados['media']}</b></h4></>)}
                </BoxBody>
            </BoxWidget>
        )
    }

}

const mapStateToProps = state => ({
    meus_cursos: state.meusCursos,
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(CursoShow)

