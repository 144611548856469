import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { date_time_loc } from '../sys/normalize-location'

import PageSetup from '../main/page-setup'
import MinhaAulaPergunta from './minha-aula-pergunta'
import MinhaAulaPerguntaAdd from './minha-aula-pergunta-add'
import MinhaAulaRespostaAdd from './minha-aula-resposta-add'
import MinhasAulasCursoList from '../minhas-aulas/minhas-aulas-curso-list'

import { ContentSection, Row, Grid } from '../common/layout'
import { BoxWidget, BoxBody, BoxFooter, BoxFooterComments, BoxComments } from '../common/widget/box'
import { ButtonDefault, BigButtonWarning, ButtonInfo, ButtonPrimary } from '../common/ui/buttons'
import { Callout } from '../common/msg/callout'

import { VideoPlay } from '../common/ui/video-play'
import { Anchor } from '../common/helper'

import { decodeBase64 } from '../sys/base64'
import { normalize_file_list } from '../sys/normalize-file-list'
import { normalize_link_video } from '../sys/normalize-link-video'

import { obter, concluir, iniciar_quest, concluir_curso, concluir_recuperacao } from './minhas-aulas-action'

import {
    obter as obterQuestResp, clear as clearQuestResp
} from '../quest-resp/resp-action'


import QuestRespShow from '../quest-resp/quest-resp-show'

class MinhaAulashow extends Component {

    carregar_aula(id_inscricao, id_aula) {
        this.props.clearQuestResp()
        this.props.obter(id_inscricao, id_aula)
    }

    concluir_aula(id_inscricao, id_aula) {
        this.props.concluir(id_inscricao, id_aula)
    }

    iniciar_quest(id_inscricao, id_aula) {
        this.props.iniciar_quest(id_inscricao, id_aula)
    }


    concluir_curso(id_inscricao, id_aula) {
        this.props.concluir_curso(id_inscricao, id_aula)
    }

    concluir_recuperacao(id_inscricao, id_aula) {
        this.props.concluir_recuperacao(id_inscricao, id_aula)
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.carregar_aula(params['id_inscricao'], params['id_aula'])
    }

    constructor(props) {
        super(props)
        this.carregar_aula = this.carregar_aula.bind(this)
        this.concluir_aula = this.concluir_aula.bind(this)
    }


    render_texto() {
        const dados = this.props.minhas_aulas.dados
        const au_tipo = dados?.tipo
        const au_fin_curso = /19/.test(au_tipo)
        const au_fin_rec = /39/.test(au_tipo)
        const au_concluida = dados['dt_termino']
        const au_tem_quest_pendente = dados['referencia_quest'] && !dados['id_resp']
        const au_concluir = !au_concluida && !(au_fin_curso || au_fin_rec) && !au_tem_quest_pendente
        const au_iniciar_quest = !au_concluir && au_tem_quest_pendente
        const tipo_quest = /16/.test(au_tipo) ? 'Quiz' : 'Questionário'
        return (
            <BoxWidget
                title="Sobre a Aula"
            >
                <BoxBody>
                    <div style={{ padding: '5px' }}>
                        <h4><b>
                            {`${dados['modulo']}.${dados['aula']} - ${dados['titulo']}`}
                        </b></h4>
                        <div dangerouslySetInnerHTML={{ __html: decodeBase64(dados['texto']) }}></div>

                    </div>
                    {dados['dt_termino'] &&
                        <span className="pull-right text-muted">Aula concluída em {date_time_loc(dados['dt_termino'])}</span>
                    }
                </BoxBody>
                {au_concluir &&
                    <BoxFooter>
                        <div className="text-center">
                            <ButtonInfo align_class="btn-lg"
                                onClick={(e) => { this.concluir_aula(dados['id_inscricao'], dados['id_aula']) }}>
                                Concluir Aula <i className="fa fa-check"></i>
                            </ButtonInfo>
                        </div>
                    </BoxFooter>
                }
                {au_iniciar_quest &&
                    <BoxFooter>
                        <div className="text-center">
                            <BigButtonWarning align_class="btn-lg"
                                onClick={(e) => { this.iniciar_quest(dados['id_inscricao'], dados['id_aula']) }}>
                                Iniciar {tipo_quest} <i className="fa fa-check"></i>
                            </BigButtonWarning>
                        </div>
                    </BoxFooter>
                }
                {this.render_controle()}
            </BoxWidget>
        )
    }


    render_arquivos() {
        const dados = this.props.minhas_aulas.dados
        const arquivos_normalizados = normalize_file_list(dados['arquivos'])
        const arquivos = (dados['arquivos'] || '').split('|').filter(e => e.length > 0)

        if (arquivos.length < 1) {
            return (
                <BoxComments>
                    <p>Esta aula não tem arquivos complementares.</p>
                </BoxComments>
            )
        }

        const endpoint = (dados['cdn_endpoint'] || '')
        return arquivos_normalizados.map((nome, idx) => {

            return (
                <BoxComments key={idx}>
                    <Anchor href={endpoint + arquivos[idx]} download={nome} target="_blank" >{nome}</Anchor>
                </BoxComments>
            )
        })
    }

    render_download() {
        return (
            <BoxWidget title="Arquivos da Aula">
                <BoxBody>
                    <BoxFooterComments>
                        {this.render_arquivos()}
                    </BoxFooterComments>
                </BoxBody>
            </BoxWidget>
        )
    }

    render_controle() {
        const dados = this.props.minhas_aulas.dados
        let show_proximo =
            dados['id_aula_next'] && dados['dt_termino'];

        if (dados['sit_resp']) {
            show_proximo = show_proximo && dados['sit_resp'] === '4';
        }

        return (
            <BoxFooter>
                {dados['id_aula_prev']
                    && <ButtonPrimary align_class="pull-left"
                        onClick={(e) => { this.carregar_aula(dados['id_inscricao'], dados['id_aula_prev']) }}>
                        <i className="fa fa-chevron-left"></i> Aula Anterior
                    </ButtonPrimary>}

                {show_proximo
                    && <ButtonPrimary align_class=""
                        onClick={(e) => { this.carregar_aula(dados['id_inscricao'], dados['id_aula_next']) }}>
                        Próxima Aula <i className="fa fa-chevron-right"></i>
                    </ButtonPrimary>
                }

                <ButtonDefault align_class="pull-right margin-r-5" >
                    <Link to={`/meu-curso-info/${dados['id_inscricao']}/${dados['id_curso']}`}>Ir para Capa <i className="fa fa-quest"></i></Link>
                </ButtonDefault>
            </BoxFooter>
        )
    }

    render_video() {
        const dados = this.props.minhas_aulas.dados
        const has_link = dados['link_video']
        return (
            <BoxWidget title={dados['titulo']}>
                <BoxBody>
                    {!has_link &&
                        <BoxFooterComments>
                            <BoxComments>
                                <p>Esta aula não possue vídeo.</p>
                            </BoxComments>
                        </BoxFooterComments>
                    }

                    {has_link && <VideoPlay link={normalize_link_video(dados['link_video'])} />}

                </BoxBody>
            </BoxWidget>
        )
    }


    render_iniciar_quest() {
        const dados = this.props.minhas_aulas.dados
        return (
            <>
                <BoxWidget title="Questionário">
                    <BoxBody>
                        <BoxFooterComments>
                            Esta aula tem um questionários para você responder. Click no botão abaixo para iniciar.
                        </BoxFooterComments>
                    </BoxBody>
                    <BoxFooter>
                        <div className="text-center">
                            <BigButtonWarning align_class="btn-lg"
                                onClick={(e) => { this.iniciar_quest(dados['id_inscricao'], dados['id_aula']) }}>
                                Iniciar o Questionário <i className="fa fa-check"></i>
                            </BigButtonWarning>
                        </div>
                    </BoxFooter>

                </BoxWidget>
            </>

        )
    }

    render_concluir() {
        const dados = this.props.minhas_aulas.dados
        return (
            <BoxWidget title="Concluir">
                <BoxBody>
                    <BoxFooterComments>
                        Após estudar o conteúdo click em concluir, para seguir para próxima atividade.
                    </BoxFooterComments>
                </BoxBody>
                <BoxFooter>
                    <div className="text-center">
                        <ButtonInfo align_class="btn-lg"
                            onClick={(e) => { this.concluir_aula(dados['id_inscricao'], dados['id_aula']) }}>
                            Concluir Aula <i className="fa fa-check"></i>
                        </ButtonInfo>
                    </div>
                </BoxFooter>
            </BoxWidget>

        )
    }

    render_aula_final() {
        const dados = this.props.minhas_aulas.dados

        if (/\b9|10|11|12/.test(dados['sit_inscricao']))
            return (
                <BoxWidget title="Curso Finalizado">
                    <BoxBody>
                        <Callout title="Parabéns, você finalizou este curso.">
                            Você pode conferir o aproveitamento na <Link to={`/meu-curso-info/${dados['id_curso']}`}>Capa do Curso</Link>.
                        </Callout>
                    </BoxBody>
                </BoxWidget>
            )


        return (
            <BoxWidget title="Finalizando o Curso">
                <BoxBody>
                    <Callout title="Parabéns, você chegou ao fim do curso.">
                        Ao confirmar a finalização sua média será computada automáticamente.
                    </Callout>
                    <div className="text-center">
                        <ButtonInfo align_class="btn-lg"
                            onClick={(e) => { this.concluir_curso(dados['id_inscricao'], dados['id_aula']) }}>
                            Finalizar o Curso <i className="fa fa-check"></i>
                        </ButtonInfo>
                    </div>

                </BoxBody>
            </BoxWidget>
        )
    }

    render_recuperacao_final() {
        const dados = this.props.minhas_aulas.dados

        if (/11|12/.test(dados['sit_inscricao']))
            return (
                <BoxWidget title="Recuperação Finalizada">
                    <BoxBody>
                        <Callout title="Parabéns, você finalizou a Recuperação.">
                            Você pode conferir o aproveitamento na <Link to={`/meu-curso-info/${dados['id_curso']}`}>Capa do Curso</Link>.
                        </Callout>
                    </BoxBody>
                </BoxWidget>
            )


        return (
            <BoxWidget title="Finalizando a Recuperação">
                <BoxBody>
                    <Callout title="Parabéns, você chegou ao fim da Recuperação.">
                        Ao confirmar a finalização sua média será computada automáticamente.
                    </Callout>
                    <div className="text-center">
                        <ButtonInfo align_class="btn-lg"
                            onClick={(e) => { this.concluir_recuperacao(dados['id_inscricao'], dados['id_aula']) }}>
                            Finalizar a Recuperação <i className="fa fa-check"></i>
                        </ButtonInfo>
                    </div>

                </BoxBody>
            </BoxWidget>
        )
    }

    render() {
        const dados = this.props.minhas_aulas.dados
        const au_tipo = this.props.minhas_aulas?.dados?.tipo
        const au_video = /10|30/.test(au_tipo)
        const au_fin_curso = /19/.test(au_tipo)
        const au_fin_rec = /39/.test(au_tipo)
        const au_quiz = /16/.test(au_tipo)

        const au_tem_quest = dados['id_resp']
        const au_tem_tutoria = dados['tipo_curso'] === '2'
        return (
            <PageSetup title='Aula'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-8">
                            {au_video && this.render_video()}
                            {au_tem_quest &&
                                <QuestRespShow
                                    goAula={false}
                                    reload_aula={true}
                                    quiz={au_quiz}
                                />
                            }
                            {au_fin_curso && this.render_aula_final()}
                            {au_fin_rec && this.render_recuperacao_final()}

                            {au_tem_tutoria && <MinhaAulaPergunta />}
                            {this.render_texto()}

                        </Grid>
                        <Grid cols="md-4">
                            {!(au_fin_curso || au_fin_rec) && this.render_download()}
                            <MinhasAulasCursoList
                                embedado={true}
                                embedado_id_aula={dados['id_aula']}
                            />
                        </Grid>
                    </Row>
                    <MinhaAulaPerguntaAdd />
                    <MinhaAulaRespostaAdd />
                </ContentSection>

            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    minhas_aulas: state.minhasAulas
})
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        obter,
        concluir,
        concluir_curso,
        iniciar_quest,
        concluir_recuperacao,
        obterQuestResp,
        clearQuestResp
    }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MinhaAulashow)

